.table-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.table-flex__wrapper {
  overflow: hidden;
  flex-grow: 1;
}

.table {
  height: 100%;
  width: 100%;
  .ag-root {
    padding: 1px;
  }

  .ag-icon {
    color: #05634e;
  }
}

label {
  font-weight: normal;
}

.table .ag-row {
  border: none;

  .ag-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.table .ag-root-wrapper {
  border: none;
}

.table .ag-header-cell-text {
  color: rgba(66, 66, 66, 0.837);
}

.table .ag-header {
  border: none;
  background-color: transparent;
}

.table .ag-body-viewport.ag-layout-normal {
  scrollbar-color: rgb(150, 150, 150);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(150, 150, 150);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
  }
}

.table .ag-body-horizontal-scroll-viewport {
  scrollbar-color: rgb(150, 150, 150);
  scrollbar-width: thin;
  overflow: hidden;

  &::-webkit-scrollbar {
    min-height: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(150, 150, 150);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
  }
}

.table .ag-row {
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 1px;
    margin-right: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }

  .ag-cell {
    border: none;
    font-size: 12px;
    font-family: "Segoe UI";
    color: #494949;
    letter-spacing: 0.2px;
  }
}

.table .ag-row-hover {
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 1px;
    margin-right: 3px;
    box-shadow: rgba(6, 137, 109, 0.077) 0px 0px 5px 0px,
      rgba(6, 137, 108, 0.4) 0px 0px 1px 0px;
  }
}

.ag-horizontal-left-spacer {
  opacity: 0;
}

.ag-root-wrapper-body {
  background-color: #f4f6f8;
  background-color: rgb(255, 255, 255);
}

.ag-header-container {
  background-color: rgb(255, 255, 255);
}

.table .ag-root {
  padding: 0px;
}

.ag-header-cell-label .ag-header-cell-text {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
}
