.palette-grid {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  border: 1px solid rgba(64, 67, 69, 0.62);
  grid-gap: 1px;
  background-color: rgba(64, 67, 69, 0.62);
  border-radius: 4px;

  .cell {
    background: #fff;
    height: 32px;
    color: #4e4e4e;
    font-size: 12px;
    padding: 6px;
    display: flex;
    align-items: center;

    .color-input__wrapper {
        margin-top: 0px;
    }
  }

  .cell-lt {
    border-radius: 4px 0 0 0;
  }

  .cell-rt {
    border-radius: 0 4px 0 0;
  }
  .cell-ld {
    border-radius: 0 0 0 4px;
  }

  .cell-rd {
    border-radius: 0 0 4px 0;
  }

  .color-input__wrapper {
      flex: 1;
  }
}
