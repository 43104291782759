.file-list {
  width: 450px;
  .accordion-section_btn {
    padding-left: 14px;
    border-radius: 3px;
    margin-bottom: 5px;
    box-shadow: 0px 0px 2px rgba(95, 95, 95, 0.6);
  }
  .accordion-section_btn:hover {
    background-color: #f4f6f8;
  }
  .accordion-section_btn > span {
    font-size: 13.5px;
    font-weight: bold;
    font-weight: 600;
  }
  .accordion-section__content {
    padding: 1px;
    background-color: transparent;
  }

  .accordion-section__content > .file-item {
    box-shadow: none;
  }

  .accordion-section__content--expanded > .file-item {
    box-shadow: 0px 0px 2px rgba(95, 95, 95, 0.6);
  }
}
