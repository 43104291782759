.search-bar {
  &.search-bar__horizontal {
    display: flex;
    direction: row;
    align-items: center;
  }

  .form-line-input-wrapper {
    margin-top: 0px;
    margin-right: 8px;
  }

  .wizard-button {
    max-height: 32px;
    min-width: 0px;
    width: auto;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
