.legend-container {
  position: absolute;
  width: 100px;
  width: auto;
  height: auto;
  left: 10px;
  padding: 6px 10px 10px 6px;
  top: 325px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 2000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  cursor: default;
}

.legend-hdr {
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-bottom: 4px;
  letter-spacing: 0.6px;
  font-weight: 600;
}

.break-line {
  margin-right: -10px;
  margin-left: -7px;
  border-width: thin;
  border-color: rgba(221, 221, 221, 0.286);
  margin-bottom: 4px;
  margin-top: 4px;
}
