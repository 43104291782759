@import "../../../common/variables.scss";

.tree-editor-container {
  width: 350px;
  padding: 10px;
}

.tree-editor-hdr {
  font-size: 12px;
  margin-bottom: 12px;
  font-weight: 400;
  color: #4f4f4f;
  margin-top: 0px;
  text-align: center;
}

.tree-coord-label-container {
  font-size: 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 18px;
  align-items: center;
  letter-spacing: 0.2px;
  margin-top: 24px;
}

.minified-icon {
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.tree-coords-line {
  margin-top: 11px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tree-icon {
  height: 16px;
  width: 16px;
  background-image: url("tree-icon.svg");
  display: inline-block;
  margin-right: -10px;
  background-size: 15px;
  background-repeat: no-repeat;
}

.tree-highlighted-icon {
  height: 16px;
  width: 16px;
  background-image: url("tree-icon-highlighted.svg");
  display: inline-block;
  margin-right: -10px;
  background-size: 15px;
}

.emission-icon {
  background-image: url("../Emission/EmissionEditor/home-icon.svg");
}
.emission-highlighted-icon {
  background-image: url("../Emission/EmissionEditor/home-icon-highlighted.svg");
}

.map-poi-icon {
  background-image: url("../MapPoi/MapPoiEditor/map-poi-icon.svg");
}
.map-poi-highlighted-icon {
  background-image: url("../MapPoi/MapPoiEditor/map-poi-highlighted-icon.svg");
}

.wind-turbine-icon {
  background-image: url("../WindTurbine/WindTurbineEditor/wind-turbine-icon.svg");
}

.wind-turbine-highlighted-icon {
  background-image: url("../WindTurbine/WindTurbineEditor/wind-turbine-highlighted.svg");
}

.tree-index {
  font-size: 11.5px;
  margin-left: 5px;
}

.tree-editor-coord-row {
  height: 25px;
  font-size: 11px;
  padding-left: 10px;
  font-weight: 500;
  font-family: "Arial", sans-serif;
  width: 110px;
  letter-spacing: 0.2px;
  border: 1px #999999 solid;
  border-radius: 3px;
  outline: none;
  &:focus {
    outline-color: #505050;
    outline: width 0.5px;
  }

  &:focus-within,
  &:hover {
    background-color: $inputFocusedColor;
  }
}

.tree-form-btn {
  background-color: white;
  outline: none;
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  justify-content: center;
  padding: 0px;
  border: 0px solid black;
  &:hover {
    cursor: pointer;
    border-radius: 4px;
    background-color: #c4c4c487;
  }
}

.remove-tree-btn {
  font-size: 9px;
  outline: none;
  height: 16px;
  width: 16px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-weight: 300;
  border-radius: 1px;
  padding: 2px;
  line-height: 0px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  &:hover {
    background-color: rgb(204, 133, 133);
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
}
