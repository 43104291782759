.map-btn {
  width: 36px;
  height: 36px;
  background-color: white;
  z-index: 800;
  outline: none;
  border: 0px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  pointer-events: visible;
  box-sizing: border-box !important;
}

.map-btn:hover {
  background-color: #f4f4f4;
}

.map-btn:active {
  box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
  background-color: #f4f4f4;
}

.map-btn:disabled {
  opacity: 0.65;
  cursor: default;
  box-shadow: none;
  background-color: white;
}

.center-view {
  background-image: url("center-view.svg");
}

.drawing-mode {
  background-image: url("drawing-mode.svg");
}

.legend {
  background-image: url("legend.svg");
}

.area-draw {
  background-image: url("area-draw.svg");
}

.tree-btn {
  background-image: url("tree.svg");
}

.exit-drawing-mode {
  background-image: url("exit-drawing-mode.svg");
}

.settings {
  background-image: url("settings.svg");
}
.export-all-data {
  background-image: url("export-all-data.svg");
}

.save {
  background-image: url("save.svg");
}

.home {
  background-image: url("home.svg");
}

.street-ico {
  background-image: url("street-ico.svg");
}

.wind-turbine {
  background-image: url("wind-turbine.svg");
}

.map-btn-pushed {
  box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
}

.transparent-template {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  box-shadow: none;
  width: 0px;
  padding: 0px;
  cursor: default;
  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
}

.no-opacity:disabled {
  opacity: 1;
  background-color: #eee;
}
