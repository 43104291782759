.page {
  .table-container {
    min-width: 800px;
    min-height: 300px;
  }
}

.projects-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 12px;
}

.projects-heading {
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  text-align: left;
}

.new-proj__btn {
  height: 32px;
  border: 1px solid gray;
  border-radius: 2px;
  font-size: 11px;
  color: rgb(76, 75, 75);
  cursor: pointer;
  letter-spacing: 0.5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  &:hover {
    background-color: #058267;
    color: white;
    border: 1px solid white;
    border-radius: 2px;
  }
  &:active {
    box-shadow: inset 0px 0px 16px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid white;
    border-radius: 2px;
  }
}

.horizontal-line-wrapper {
  display: flex;
  justify-content: center;
}

.horizontal-line {
  border-top: 1px solid #e4e4e4;
  padding-bottom: 17px;
}

.start-img {
  position: absolute;
  max-width: 240px;
  height: auto;
  right: 0px;
  bottom: 87px;
  box-shadow: 0 6.4px 14.4px 0 #00000021;
  border-radius: 5px;
}
