.form_separator {
  width: 100%;
  height: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  & > .form-separator__label {
    color: #495057;
    font-weight: 600;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  & > .form-separator__line {
    height: 1px;
    border: none;
    border-top: 1px solid rgba(100, 105, 110, 0.62);
  }
}

.form_section {
  width: 100%;
  height: 18px;
  margin-top: 27px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  cursor: default;

  & > .form-section__txt {
    //color: #495057;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    font-size: 20px;
    margin-top: 4px;
    margin-bottom: 4px;
    cursor: default;
  }
  & > .form-section__ruler {
    border: none;
    border-top: 1px solid rgba(100, 105, 110, 0.62);
  }
}
