@import "../../../common/variables.scss";

.navbar-wrapper {
  min-height: 40px;
  background-color: $primaryColor;
  align-items: center;
  z-index: 500000000000000;
  display: flex;
  justify-content: space-between;
  top:0px;
  position:sticky;
}

.main-nav {
  display: flex;
  list-style: none;
  text-transform: uppercase;
}

.main-nav a {
  display: block;
}
.logo-wrapper {
  align-items: center;
  height:100%
}

.burger-nav {
  width: 26px;
  height:100%;
}
.burger-nav-btn {
  outline: 0;
  border: 0px solid black;
  background-color: transparent;
  margin-left: 10px;
  cursor: pointer;
  width: 40px;
  padding:0;
  height:100%;

  &:hover {
    background-color: #086853;
  }
}
