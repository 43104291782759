.circle-button {
  min-height: 24px;
  min-width: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #06896c;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px 0px rgba(66, 88, 65, 0.25);
  cursor: pointer;
  color: white;
  &:hover {
    background-color: rgba(6, 137, 108, 0.8);
  }

  .circle-button_icon {
    width: 17px;
    height: 17px;
  }
}

.button-disabled {
  pointer-events: none;
  background-color: rgba(115, 124, 122, 0.6) !important;
}
