.wizard-section__geodata {
  overflow-y: auto;
  scrollbar-color: rgb(150, 150, 150);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(150, 150, 150);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
  }
  .geodata-clone {
    margin-bottom: 36px;
    .text-input-container:last-child {
      margin-bottom: 20px;
    }
  }

  .geodata-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-clone-toggle {
      align-self: center;
      height: 32px;
      background-color: rgb(6, 137, 108);

      &:hover {
        background-color: rgba(6, 137, 108, 0.8);
      }
    }
  }

  .data-checkboxes {
    width: 300px;
    margin-top: 24px;
    font-size: 12px;
    color: #4e4e4e;
    font-family: "Segoe UI", sans-serif;
    border: 1px solid rgba(100, 105, 110, 0.62);
    user-select: none;
    padding-top: 10px;
    legend {
      font-weight: 600;
      color: #495057;
    }
    .cbx-wrapper {
      margin-bottom: 10px;
      .cbx {
        margin-right: 10px;
      }
    }
    .cbx-wrapper:last-child {
      margin-bottom: 0px;
    }
  }

  .error-section {
    //margin-top: -16px;
    //margin-top:12px;
    margin-bottom: 36px;
    margin-top: 24px;
  }
}
