.color-input__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .color-input {
    display: inline-block;
    border-radius: 2%;
    max-height: 40px;
    flex: 1;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
  }
}
