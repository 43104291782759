.footer-wrapper {
  background-color: #06896c;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: center;
}

.footer-txt {
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: "Roboto";
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
}
