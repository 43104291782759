.static-driver-btn-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 90px;
  left: 10px;
  height: 275px;
  width: auto;
  z-index: 800;
  pointer-events: none;
  z-index: 1000;
}

.layer-settings {
  background-image: url("layer-settings.svg");
}

.layer-save {
  background-image: url("layer-save.svg");
}

.export {
  background-image: url("export.svg");
}

.pin-location {
  background-image: url("pin-location.svg");
}

.print {
  background-image: url("print.svg");
}

.image {
  background-image: url("image.svg");
}

.geodata-download {
  background-image: url("geodata-download.svg");
}

.area {
  background-image: url("area.svg");
}

.area-poi {
  background-image: url("area-poi.svg");
}

.hid-area-poi {
  background-image: url("hid-area-poi.svg");
}

.custom-map-export {
  background-image: url("custom-map-export.svg");
}

.chart-timeseries {
  background-image: url("chart-timeseries.svg");
}

.chart-vertical {
  background-image: url("chart-vertical.svg");
}

.camera {
  background-image: url("camera.svg");
}
