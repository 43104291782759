.Select.is-disabled > .Select-control {
  cursor: not-allowed;
  color: #aaa !important;
}

.horizontal-settings-ruler {
  margin-top: 24px;
  height: 1px;
  margin-bottom: 16px;
  border-top: 1px solid rgba(100, 105, 110, 0.62);
}

.setup-page {
  background-color: white;
  box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132);
  display: flex;
  font-family: "Segoe UI", sans-serif;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  min-width: 840px;
  padding: 30px 50px 55px 50px;
  margin-top: 30px;
  margin-bottom: 40px;
  width: 60%;
}

.header-main-icon {
  display: inline;
}

.header-icon-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-main {
  font-size: 32px;
  font-weight: 700;
  margin-left: 14px;
}

.settings-form .wizard-button {
  width: 120px;
}

.setup-warning {
  background-color: #c242425c;
  margin: 0;
  color: #a81d1dcf;
  font-family: "Segoe UI";
  padding: 6px;
  margin-bottom: 12px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.setup-warning-info {
  margin-left: 6px;
  padding-bottom: 1px;
  font-weight: 500;
  font-size: 13px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

input[type="time"] {
  font-size: 12px;
  //border: none;
  color: #495057;
  font-family: "Segoe UI";

  &:active,
  &:focus {
    //border: 0px solid white;
    outline: none;
  }
}
.setup-time-container {
  height: 31px;
  border: 1px solid rgba(100, 105, 110, 0.62);
  padding-left: 10px;
  font-size: 12px;
  outline: none;
  color: #495057;
  border-radius: 3px;
  font-weight: 600;
  font-family: "Segoe UI";
  display: flex;
  justify-content: space-between;
}

.time-unit {
  padding-right: 8px;
  border: none;
  align-self: center;
  font-family: "Segoe UI", sans-serif;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}

.simulation-time-container {
  display: flex;
  align-items: center;
  margin-left: -5px;
}

.simulation-time-input {
  font-size: 12px;
  outline: none;
  color: #495057;
  border: 0px;
  width: 36px;
  font-family: "Segoe UI";
  caret-color: rgb(255, 255, 255);
  cursor: default;
  &::-webkit-inner-spin-button {
    opacity: 1; // magic!
  }
}

.simulation-time-separator {
  padding-bottom: 1px;
  cursor: default;
}

.setup-input {
  min-height: 31px;
  height: 31px;
  border: 1px solid rgba(100, 105, 110, 0.62);
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 12px;
  outline: none;
  color: #495057;
  border-radius: 3px;
  font-family: "Segoe UI";
  //font-weight: 600;
}

.setup-input.disabled {
  background-color: #ececec;
}

.setup-save-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.setup-save-status-wrapper {
  display: flex;
  align-items: center;
  animation: fadeInSetupStatus 0.5s;
  padding-left: 12px;
  cursor: default;
}

#setup-btn__save {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  padding: 8px;
  span {
    margin-right: 8px;
  }
}

.save-status-text {
  font-size: 13px;
  color: rgb(6, 137, 108);
  font-weight: 600;
  padding-left: 8px;
}

.save-status-disabled {
  font-size: 13px;
  color: rgb(172, 65, 65);
  font-weight: 600;
  padding-left: 8px;
}

@keyframes fadeInSetupStatus {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input[type="date"],
input[type="time"] {
  font-family: "Segoe UI", sans-serif;
  color: #495057;
}

.datetime-input-edit-wrapper {
  margin-left: -4px;
}

.wind-sim-status-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
  margin-bottom: 30px;
  padding: 14px;
  border-radius: 2px;
}

.sim-number {
  font-size: 15px;
  margin-bottom: 16px;
  font-weight: 600;
}
