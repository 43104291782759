@import "../../../../common/variables.scss";


.badge {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #086853;
  }
}

.badge__active {
  background-color: #086853;
}

.badge-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.badge-indicator {
  position: absolute;
  display: none;
  height: 14px;
  width: 14px;
  top: 18px;
  right: 8px;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 0 0 0 2px $primaryColor;
}

.badge-indicator__new {
  background: #da3225;
  display: block;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
	animation: pulse 2s infinite;
}

.badge:hover .badge-indicator {
  box-shadow: 0 0 0 2px #086853;
}

.badge__active .badge-indicator {
  box-shadow: 0 0 0 2px #086853;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
