.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Segoe UI";
}

.modal-info {
  text-align: left;
  font-size: 14px;
  margin-bottom: 30px;
  color: #2c2c2c;
  margin-top: 8px;
  font-weight: 500;
  line-height: 22px;
}

.modal-hdr-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 19px;
}

.modal-hdr {
  color: #444;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.modal-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btn-modal-delete {
  background-color: #06896dc9;
  padding: 7px 20px 7px 20px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  border: 0px;
  font-family: "Segoe UI";
  font-weight: 600;
  min-width: 83px;
  border: 1px solid #3c3c3c;

  &:hover {
    background-color: #06896c;
  }

  &:disabled {
    background-color: #f2f2f2; /* Light gray background for disabled state */
    color: #c2c2c2; /* Muted text color */
    opacity: 0.8; /* Slight transparency for visual feedback */
    pointer-events: none;
    cursor: pointer;
  }
}

.btn-modal-cancel {
  padding: 7px 20px 7px 20px;
  color: #2c2c2c;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid gray;
  font-family: "Segoe UI";
  font-weight: 600;
  min-width: 83px;
  margin-right: 12px;
  background-color: white;

  &:hover {
    background-color: #2c2c2c18;
  }

  &:disabled {
    background-color: #f2f2f2; /* Light gray background for disabled state */
    color: #c2c2c2; /* Muted text color */
    opacity: 0.8; /* Slight transparency for visual feedback */
    cursor: pointer;
    pointer-events: none;
  }
}

.btn-modal-delete:active {
  background-color: #056e57c9;
  box-shadow: inset 0px 0px 12px -8px rgb(0, 0, 0);
}

.btn-modal-cancel:active {
  background-color: #96969617;
  box-shadow: inset 0px 0px 12px -8px rgb(0, 0, 0);
}

.exit-modal {
  padding: 1px;
  border-radius:1px;
}
.exit-modal:hover {
  background-color: #f0f0f0;
  stroke: #111111;
  border-radius: 2px;
}

.exit-modal:active {
  box-shadow: inset 0px 0px 12px -8px rgba(159, 159, 159, 0.322);
}
