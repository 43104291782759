.option-palette {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding-right: 10px;

  .option-palette__color {
    height: 24px;
    width: 24px;
  }

  .option-palette__label {
    text-align: center;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    //cursor: pointer;
  }
}

.control-palette {
  width: 100%;
}

.select__single-value {
  width: 0;
}
.palette-wrapper {
  height: 100%;
  width: 100%;
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .palette-section {
    flex: 1;
    height: 20px;
    position: relative;

    .palette-section_input {
      color: transparent;
      background-color: transparent;
      position: absolute;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
