.wizard-wrapper {
  height: auto;
  font-family: "Segoe UI", sans-serif;
  position: relative;
  display: table;
  width: 800px;
  table-layout: fixed;
  border-collapse: collapse;
  z-index: 1;
  margin-bottom: 52px;
  margin-top: 40px;
}

.stepper-step {
  position: relative;
  display: table-cell;
  text-align: center;
  padding: 0.5rem;
}

.stepper-indicator {
  position: relative;
  display: block;
  z-index: 2;
}

.stepper-label {
  position: relative;
  display: block;
  z-index: 2;
  font-size: 12px;
  color: #37393b;
  font-weight: 600;
}

.stepper-info {
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: 3px solid #939393;
  border-radius: 50%;
  background-color: white;
  font-size: 12px;
  line-height: 1rem;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.stepper-panel {
  display: none;
}

.stepper .stepper-step:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 17.2px;
  width: 100%;
  height: 0.125rem;
  background-color: #008eff;
  background-color: #939393;
  z-index: 1;
}
.stepper .stepper-step:last-child:after {
  display: none;
}

.step-completed::after,
.stepper-info-completed {
  background-color: #4be96b !important;
  border-color: #4be96b;
}

.step-failed::after,
.stepper-info-failed {
  background-color: #e95a4b;
}

.step-in-progress::after,
.stepper-info-in-progress {
  background-color: #c6ce24;
}

.step-active {
  border: 3px solid #06896c;
}
