@import "../../../../common/variables.scss";

.dropdown-user-wrapper {
  background-color: white;
  right: 5px;
  top: 38px;
  width: 200px;
  height: 216px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #424446;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  font-size: 13px;
  font-family: "Segoe UI", sans-serif;
  margin-top: 4px;
  letter-spacing: 0.3px;
  border: 1px solid #dadce0;
}

.dropdown-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin-bottom: 6px;
}

.dropdown-user-data-wrapper {
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.dropdown-option-wrapper {
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #06896c17;
    color: rgb(27, 27, 27);
  }
}

.dropdown-data {
  width: auto;
  height: auto;
  color: #424446;
  text-decoration: none;
}

.dropdown-data,
.dropdown-data:hover,
.dropdown-data:focus,
.dropdown-data:active {
  text-decoration: none;
}

.dropdown-mail-wrapper {
  height: 12px;
  margin-bottom: 12px;
}

.dropdown-email {
  font-size: 12px;
  cursor: auto;
  letter-spacing: 0.25px;
  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.dropdown-ruler {
  color: white;
  width: 170px;
  border: none;
  height: 1px;
  background: #e4e4e4;
  margin-top: 8px;
}

.dropdown-surname {
  font-size: 14px;
}

.sign-out-wrapper {
  margin-top: 8px;
}

.dropdown-option__link {
  text-decoration: none;
}
