.legend-row-container {
  line-height: normal;
  width: 100%;
  height: 100%;
}

.legend-checkbox {
  position: relative;
  vertical-align: middle;
}

.legend-object-name {
  color: black;
  margin-left: 6px;
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  word-break: break-all;
}
