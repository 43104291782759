.form-wrapper {
  background-color: white;
  width: 600px;
  box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132);
  padding: 50px;
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", sans-serif;
  padding-top: 30px;
  height: auto;
  padding-bottom: 50px;
  border-radius: 5px;
}
.form-header {
  height: auto;
  text-align: center;
  color: #37393b;
}

.form-line-input-wrapper {
  // height: 50.6px;
  margin-top: 20px;
  //vertical form really good as well
  display: flex;
  flex-direction: column;
}

.form-line-input-wrapper-palette {
  margin-top: 0px;
  margin-bottom: 16px;
}

.text-input-form-label {
  color: #495057;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  font-family: "Segoe UI", sans-serif;
  // width: 150px;
  margin-bottom: 4px;
}

.form-input-ui {
  font-size: 11px;
  width: 300px;
  border: 1px solid rgba(100, 105, 110, 0.62);
  color: #495057;
  padding: 12px 12px 12px 12px;
  line-height: 1.6;
  border-radius: 3px;
  outline: 0;
  padding: 6px;
  box-sizing: border-box;
  //letter-spacing: 0.6px;
  height: 31px;
  font-family: "Segoe UI";
  &:focus {
    outline: 0;
    border: 1px solid rgba(55, 55, 55, 0.708);
  }
}

.form-header-wrapper {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.card-icon {
  width: 24px;
}

.general-settings-form-input-ui {
  width: 100%;
}

.form-layer-wrapper-palette {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
}

.form-palette-layer {
  width: 200px;
}

.form-palette-label {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: rgb(78, 78, 78);
  font-family: "Segoe UI", sans-serif;
  font-weight: 600;
}
