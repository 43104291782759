.pin-location-map {
  width: 100px;
  height: 100px;
}

.results-form-btn {
  display: flex;
  height: 20px;
  width: 20px;
  padding: 0px;
  align-items: center;
  outline: none;
  justify-content: center;
  border: 0px solid black;
  background-color: white;
  &:hover {
    cursor: pointer;
  }
}

.popup-container {
  font-family: "Segoe UI";
  font-size: 14px;
  text-align: center;
  box-shadow: none;
  font-weight: 600;
  cursor: default;
}

.leaflet-popup {
}
.leaflet-popup-content-wrapper {
  background: purple;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  background: purple;
  color: white;
}

.leaflet-popup-tip {
  background-color: purple;
}

.a.leaflet-popup-close-button {
  color: #fff;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #fff;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #fff !important;
  opacity: 0;
}

.leaflet-control-browser-print a {
  //background: #fff url("./../StaticDriverMapPanel/print.svg") no-repeat !important;
}

.leaflet-control-browser-print {
  left: 0px;
  top: 15px;
  border: 0px solid black !important;
}

.leaflet-control-browser-print a {
  border-radius: 5px !important;
  cursor: pointer;
  height: 36px !important;
  width: 36px !important;
  border: 0px solid black !important;
  left: -2px;
  top: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

ul.browser-print-holder {
  cursor: pointer;
  backdrop-filter: blur(20px);
}

li.browser-print-mode,
.v1.browser-print-mode {
  width: 80px;
  padding: 10px 12px 10px 12px !important;
  height: 36px;
  font-family: "Segoe UI" !important;
}

.browser-print-holder {
  background-color: rgba(126, 126, 126, 0.319) !important;
  color: black !important;

  :hover {
    color: white !important;
    background-color: rgba(115, 115, 115, 0.501) !important;
    bottom: 15px;
  }
}

.leaflet-top .leaflet-control-browser-print .leaflet-browser-print {
  &:hover {
    background-color: #f4f4f4 !important;
  }
  background: #fff url("./../StaticDriverMapPanel/print.svg") no-repeat !important;
}
.leaflet-top
  .leaflet-control-browser-print
  ~ .leaflet-control-browser-print
  .leaflet-browser-print {
  background: #fff url("./../StaticDriverMapPanel/image.svg") no-repeat !important;
  &:hover {
    background-color: #f4f4f4 !important;
  }
}

div.leaflet-control-browser-print:nth-child(2) {
  margin-top: 11px;
}

div.leaflet-control-browser-print:nth-child(3) {
  margin-top: 8px;
}
