.table-icon__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Segoe UI", sans-serif;
}

.table-icon {
  height: 19px;
  width: 19px;
  color: rgb(5, 99, 78);
  cursor: pointer;

  &:hover {
    color: rgba(5, 99, 78, 0.7);
  }
}
