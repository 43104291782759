.geotiff-scale-wrapper {
  position: absolute;
  width: 91px;
  height: 230px;
  top: 450px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  padding: 6px 10px 6px 15px;
  background-color: white;
  z-index: 1000;
}

.legend-geotiff-header {
  margin-bottom: 6px;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
}

.scale-container {
  width: 100%;
  height: 0px;
}

.scale-row-container {
  display: flex;
  flex-direction: row;
  &:last-child .height-color-tile {
    border-bottom: 1px solid black;
  }
}

.height-color-tile {
  width: 20px;
  height: 20px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.height-dscr-container {
  margin: 0;
  display: flex;
  align-items: center;
  margin-left: 9px;
}

.height-dscr {
  display: inline-flex;
  margin: 0;
  vertical-align: middle;
  font: 11.5px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: black;
}
