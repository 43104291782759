// input[type="range"]::-webkit-slider-thumb {
//   -webkit-appearance: none; /* Removes default styling for customization */
//   appearance: none;
//   width: 12px; /* Smaller thumb size */
//   height: 12px; /* Smaller thumb size */
//   background: #06896c; /* Thumb color */
//   border-radius: 50%; /* Make it circular */
//   cursor: pointer; /* Cursor changes to pointer on hover */
// }

input[type="range"]::-moz-range-thumb {
//   width: 12px;
//   height: 12px;
  //background: #06896c;
  //border-radius: 50%;
  cursor: pointer;
}

// input[type="range"]::-ms-thumb {
//   width: 12px;
//   height: 12px;
//   background: #06896c;
//   border-radius: 50%;
//   cursor: pointer;
// }

// .anim-slider {
//   background: red;
// }

input[type="range"] {
  accent-color: #06896dc9;
  cursor: pointer;
}
