.sim-tabs-wrapper {
  //background-color: beige;
  //   border: 1px solid #f0f0f0;
  //   border-top: 0px solid white;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13);
}

.sim-tabs-content {
  //   border: 1px solid #f0f0f0;
  //   border-top: 0px solid white;
  padding: 24px;
  min-height: 800px;
}
.sim-tabs-nav {
  display: table;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 0px;
  font-weight: 600;
  color: #757575;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.sim-tabs-nav li {
  display: table-cell;
  background-color: #f0f0f0;
  border-top: 2px solid transparent;
  padding: 1rem;
  text-align: center;
  list-style: none;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.1px;
  &:hover {
    background-color: #f4f4f4;
  }
}

.sim-tabs-nav .sim-tabs-active {
  background-color: white;
  border-top: 2px solid #06896c;
  // border-left: 0px white solid;
  // border-right: 0px solid white;
  &:hover {
    background-color: white;
  }
}

.sim-tabs-disabled {
  background-color: #a0a5aa;
  border-top: 2px solid #666666;
  border-left: 0px white solid;
  color: #a0a5aa;
  pointer-events: none;
}

.sim-btn-container {
  display: flex;
  flex-direction: column;
}

.sim-btn-text {
  margin-left: 5px;
}

.sim-btn-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  cursor: pointer;
}

// .sim-btn {
//   min-width: 100px;
//   border-radius: 4px;
//   color: #4e4e4e;
//   font-family: "Segoe UI";
//   cursor: pointer;
//   outline: none;
//   font-size: 12px;
//   border: 1px solid gray;
//   background-color: white;
//   padding: 8px 12px 8px 12px;
//   font-weight: 600;
// }

// .sim-btn-top {
//   margin-top: 12px;
// }

// .sim-btn:active {
//   background-color: #96969617;
//   box-shadow: inset 0px 0px 12px -8px black;
// }

// .sim-btn:hover {
//   color: #2b2b2b;
//   background-color: #63747017;
// }

// .sim-btn-disabled {
//   background-color: rgb(240, 240, 240);
//   box-shadow: none;
//   padding: 8px 12px 8px 12px;
//   font-weight: 600;
//   margin-bottom: 12px;
//   border-radius: 4px;
//   color: #4e4e4e;
//   font-family: "Segoe UI";
//   outline: none;
//   font-size: 12px;
//   border: 1px solid gray;
//   cursor: default;
// }

.btn-palm-mon-content-wrapper {
  justify-content: flex-start;
}
