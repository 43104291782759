input[type="file"] {
  display: none;
}

.file-browser {
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 31px;
}
.custom-file-upload {
  padding: 4px 8px;
  cursor: pointer;
  width: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 3px 0px 0px 3px;
  background-color: #e9ecef;
  //border: 1px solid #ced4da;

  border: 1px solid rgba(100, 105, 110, 0.62);
  color: #9ca2a5;

  &:hover {
    color: #495057;
  }
  &:active {
    box-shadow: inset 0px 0px 12px -10px rgba(0, 0, 0, 0.75);
  }
}

.custom-file-upload__disabled {
  background: #e9ecef;
  //border: 1px solid #ced4da;

  color: #9ca2a5;
  cursor: default;

  &:hover {
    color: #9ca2a5;
    cursor: not-allowed;
  }
  &:active {
    box-shadow: none;
  }
  + .file-preview {
    background: #e9ecef;
    cursor: not-allowed;
  }
}

.file-preview {
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid rgba(100, 105, 110, 0.62);
  border-left: 0px solid white;
  border-radius: 0px 3px 3px 0px;
  font-size: 11px;
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
  justify-content: space-between;
  word-break: break-all;
  cursor: default;
}

.file-name {
  color: #393939;
  height: auto;
  padding-left: 8px;
  line-height: 1;
}

.custom-setup-file-upload {
  width: 400px;
}
