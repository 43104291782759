.icon-button {
  min-height: 18px;
  min-width: 18px;
  height: 18px;
  width: 18px;

  .icon-button_icon {
    height: 100%;
    width: 100%;
    stroke: #b2babb;
    stroke-width: 2;

    &:hover {
      stroke:  #06896c;
    }
  }
}
