.case-container {
  padding-bottom: 20px;
  width: 300px;
}

.form-warning {
  font-size: 10px;
  color: rgb(204, 122, 0);
  margin-top: 4px;
}
.case-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button.wizard-button {
    height: 32px;
    margin-top: 0px;
    align-self: center;
  }
}

.geodata-container {
  margin-top: 20px;
  .form-warning {
    position: relative;
    top: -4px;
  }

  .file-browser-wrapper {
    margin-top: 10px;
  }
}

.case-container .wizard-button {
  margin-top: 20px;
}

.case-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-case-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  #case-save__btn {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    padding: 8px;
    span {
      margin-right: 8px;
    }
  }
}

.case-container .text-input-form-label {
  font-size: 11px;
}

.case-container .file-browser-label {
  font-size: 11px;
}
