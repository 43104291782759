.ui-select-wrapper {
  height: 51px;
  margin-top: 20px;
  //vertical form really good as well
  display: flex;
  flex-direction: column;
}

.ui-label-select {
  color: #495057;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  width: auto;
  margin-bottom: 4px;
}

.ui-select-input {
  font-size: 12px;
  width: 300px;
  font-family: "Segoe UI";
  border: 1px solid rgba(100, 105, 110, 0.62);
  color: #495057;
  line-height: 1.6;
  border-radius: 3px;
  outline: 0;
  height: 31px;
  letter-spacing: 0.1px;
  padding: 4px 4px 4px 4px;
  &:focus {
    outline: 0;
    border: 1px solid rgba(0, 40, 100, 0.62);
  }

  option:disabled {
    color: rgb(161, 161, 161);
    background: #e7e7e9;
  }
}
