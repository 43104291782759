.wizard-section {
  background-color: white;
  width: 600px;
  box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132);
  padding: 50px;
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", sans-serif;
  padding-top: 30px;
  margin-bottom: 50px;
  padding-bottom: 35px;
  border-radius: 5px;
  margin-top: 30px;
  min-height: 404px;
  overflow-y: hidden;
}

.end {
  justify-content: flex-end !important;
}

.text-input-container {
  display: flex;
  flex-direction: row;
}

.validation-container {
  margin-top: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.validation-wrapper {
  font-size: 12px;
  margin-left: 8px;
  color: #fc3939e3;
  font-weight: 600;
}
.validation-icon-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.legend-label {
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 20px;
  font-weight: 600;
}
