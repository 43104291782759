.sidebar-wrapper {
  height: 100vh;
  width: 160px;
  z-index: 1000000;
  left: -225px;
  background-color: white;
  width: 225px;
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
  overflow-y: auto;
}

.sidebar-show {
  left: 0px;
  box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132),
    0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sidebar-items:first-child {
  margin-top: 50px;
  height: 100%;
}

.sidebar-section__separator {
  height: 1px;
  border-top: 1px solid #afafaf;
  margin-top: 12px;
  margin-bottom: 12px;
}
