.files-page-wrapper {
  width: 450px;
}

.file-upload {
  display: flex;
  flex-direction: row;

  .file-browser-wrapper {
    margin-top: 0px;
    margin-right: 8px;
  }

  .icon-button {
    position: relative;
    top: 7px;
    cursor: pointer;
  }
  .loader {
    top: -28px;
  }
}

.file-list__tiff
  > .accordion-section
  > .accordion-section__content
  > .file-item
  > .file-card
  > .file-card-wrapper
  .file-card__icon--main {
  position: absolute;
  height: 35px;
  width: 35px;
  top: 15px;
}
