.file-browser-wrapper {
  height: 51px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", sans-serif;
}

.file-browser-label {
  color: #495057;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  width: auto;
  margin-bottom: 4px;
}
