.main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  /* zapewnia plynna aminacje panelu!!!! */
  font-family: "Roboto", sans-serif;
  box-sizing: content-box;

  *,
  *::before,
  *::after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

button::-moz-focus-inner {
  border: 0;
}
