.raster-settings-header {
  margin-top: 16px;
  letter-spacing: 0.3px;
  margin-bottom: 30px;
  margin-bottom: 12px;
  text-align: center;
  font-family: "Segoe UI";
  cursor: default;
}

.raster-settings-btn-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;
}

.prev-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.raster-settings-btn {
  border-radius: 2px;
  color: #4e4e4e;
  font-family: "Segoe UI";
  cursor: pointer;
  outline: none;
  font-size: 12px;
  //border: 1px solid gray;
  border: 1px solid #a1a1a1;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  margin-top: 30px;

  &:hover {
    color: rgb(43, 43, 43);
    //background-color: #63747017;
    background-color: #f9f9f9;
  }
  &:active {
    background-color: #96969617;
    box-shadow: inset 0px 0px 12px -8px rgb(0, 0, 0);
  }
}

.raster-settings-btn-active {
  background-color: #96969617;
  box-shadow: inset 0px 0px 12px -8px rgb(0, 0, 0);
}

.raster-settings-btn:disabled {
  //background-color: #e3e3e3;
  background-color: #f0f0f0;
  box-shadow: none;
  color: #4e4e4e;
  cursor: default;
}

.raster-settings-ruler {
  color: white;
  width: 100%;
  border: none;
  height: 1px;
  background: #cdcdcd;
  margin-top: 20px;
  margin-bottom: 20px;
}

.raster-btn-lbl {
  margin-left: 6px;
}

.raster-switch-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.raster-switch-lbl {
  font-size: 10px;
  margin-right: 4px;
  margin-left: 4px;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-family: "Segoe UI";
}

.raster-settins-expert-container {
  margin-top: 16px;
}

.select__input {
  color: transparent;
}

.legend-wrapper {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.raster-object-name {
  font-size: 12px;
  color: #4e4e4e;
  font-family: "Segoe UI", sans-serif;
  width: 400px;
  font-weight: 600;
  margin-left: 6px;
}

.layer-object-name {
  font-size: 11px;
  width: 98px;
  margin: 0px;
}

.case-home-btn {
  &:hover {
    color: #fff;
    background-color: #06896c;
    border: 0px solid #fff;
    .case-save-icon {
      color: #fff;
    }
    &:active {
      background-color: #06896c;
    }
  }
}

.case-save-icon {
  height: 16px;
  width: 16px;
}

.case-btn-lbl {
  display: none;
  //width: 0;
  //overflow: hidden;
}

.case-sdb-btn {
  font-size: 11px;
  &:hover {
    //width: 200px;
    .case-btn-lbl {
      display: block;
    }
  }
}

.nav-case-btn {
  height: 16px;
  width: 16px;
}
