.settings-form {
  display: flex;
  flex-direction: column;
  
  .user-settings__palette {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 28px;
  }

  & > .form-line-input-wrapper {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .simulation-presets {
    width: 100%;
  }
}

.ws-maps {
  width: 100%;
  margin-right: 40px;
}

.ws-sim-container {
  display: flex;
  flex-direction: row;
}

.settings-btn {
  margin-top: 32px;
  width: 120px;
  padding: 7px 10px;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 11px;
  color: rgb(76, 75, 75);
  letter-spacing: 0.5px;
  cursor: pointer;
  &:hover {
    background-color: #058267;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
  }
  &:active {
    box-shadow: inset 0px 0px 16px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid white;
    border-radius: 4px;
  }
}

.user-details-container {
  display: flex;
  .avatar {
    box-shadow: 5px 10px 5px 0px rgba(66, 88, 65, 0.25);
    .avatar-edit {
      box-shadow: 3px 3px 3px 0px rgba(66, 88, 65, 0.25);
    }
  }
}

.user-details-wrapper {
  padding: 4px 0 12px 0;
}

.avatar-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.settings-heading__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .settings-heading__name {
    font-size: 25px;
    font-weight: 500;
  }

  .settings-heading__address {
    font-size: 18px;
    font-weight: 300;
  }
}

// div.form_separator:nth-child(5) {
//   margin-top: 37px;
//   margin-bottom: 8px;
// }

.save-palette-btn-container {
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
}

.page-auto-width .form_separator {
  width: 50%;
  padding-right: 20px;
}

.user-details-container .form-label {
  font: size 11px;
  font-weight: 600;
}

.sim-preset-input {
  max-width: 100px;
}

.settings-hdr {
  margin-bottom: 28px;
}
