.sidebar-project__container {
  display: flex;
  flex-direction: column;

  .sidebar-project__name {
    font-family: "Segoe UI";
    font-size: 13px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
  }
}
