.wind-wrapper {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.table-animation {
  animation-name: table-animation;
  animation-duration: 0.1s;
  //visibility: visible !important;
  animation-timing-function: linear;
}

@keyframes table-animation {
  0% {
    transform: scale(90%);
  }
  // 50% {
  //   transform: scale(8%);
  // }
  100% {
    transform: scale(100%);
  }
}
