.status-panel-wrapper {
  display: flex;
  flex-direction: column;
}

.status-hdr {
  color: black;
  padding: 36px;
  font-family: "Segoe UI";
  font-size: 18px;
  text-align: center;
  cursor: default;
}

.status-dscr {
  color: black;
  padding: 36px;
  font-family: "Segoe UI";
  font-size: 15px;
  text-align: center;
}

.approval-tick {
  height: 37px;
}
