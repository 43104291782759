@import "../../../common/variables.scss";

.wizard-button {
  color: white;
  font-size: 11px;
  cursor: pointer;
  border-radius: 3px;
  border: 0px;
  font-family: "Segoe UI";
  font-weight: 600;
  min-width: 83px;
  &:active {
    box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
  }
}

.wizard-button-prev {
  background-color: #37393b;
  padding: 8px 16px 8px 12px;

  &:hover {
    background-color: #424547;
  }
}

.wizard-button-next {
  background-color: $primaryColor;
  padding: 8px 12px 8px 16px;

  &:hover {
    background-color: #057d63;
  }
}

.wizard-button-submit {
  background-color: $primaryColor;
  padding: 8px 16px 8px 16px;

  &:hover {
    background-color: #057d63;
  }
}

.btn-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  svg.left-icon {
    margin-right: 5px;
  }
  svg.right-icon {
    margin-left: 5px;
  }
}

.btn-text-wrapper {
  display: flex;
  align-items: center;
}

.wizard-button:disabled {
  opacity: 0.65;
  &:hover {
    box-shadow: none;
    background-color: $primaryColor;
  }
}

.wizard-button-submit:disabled {
  &:disabled {
    opacity: 0.65;
    background-color: #aeafaf;
    cursor: default;
  }
}
