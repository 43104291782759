.page {
  background-color: white;
  min-width: 840px;
  box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132);
  padding: 50px;
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", sans-serif;
  padding-top: 30px;
  padding-bottom: 55px;
  border-radius: 5px;
  margin-top: 30px;
  width: auto;
  margin-bottom: 40px;
}

.page-hdr-wrapper {
  display: flex;
  flex-direction: row;
}

.page-hdr {
  height: auto;
  text-align: center;
  color: #37393b;
  margin-bottom: 14px;
  margin-top: 14px;
}

.page-auto-width {
  width: auto;
}

.page-overflow-hidden {
  overflow: hidden;
}

.page-full-height {
  height: 100%;
}
