.form-chckbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.id-copy-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.id-copy-label {
  height: 14.815px;
  font-size: 9px;
  color: #4e4e4e;
  font-family: "Segoe UI", sans-serif;
  font-weight: 600;
}

.assign-label {
  margin-left: 80px;
  font-size: 10px;
  color: #4e4e4e;
  font-family: "Segoe UI", sans-serif;
  white-space: nowrap;
  font-weight: 600;
}
.chckbox-container {
  width: 94%;

  display: flex;
  flex-direction: column;
}
.chckbox-input-wrapper {
  height: 32px;
  height: 25px;
  display: flex;
  align-items: center;
}

.chckbox-lbl {
  font-family: "Segoe UI", sans-serif;
  color: #4e4e4e;
  font-size: 11px;
  font-weight: 600;
  margin-left: 2px;
}

.chckbox-lbl::first-letter {
  text-transform: capitalize;
}

.copy-props-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 197px;
}
