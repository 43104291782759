.accordion-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.4s ease;
  .accordion-section__header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-left: 30px;
    height: 32px;
  }

  .accordion-section_btn {
    background-color: rgb(255, 255, 255);
    color: #444;
    cursor: pointer;
    padding: 8px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;

    &:hover {
      background-color: #06896c17;
    }

    span {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: "Segoe UI";
      font-size: 12px;
      font-weight: 500;
    }

    .accordion-section__chevron {
      align-self: center;
      transition: transform 0.4s ease;
    }
  }

  .accordion-section__chevron--expanded {
    transform: rotate(90deg);
  }

  .accordion-section__content {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    background-color: rgb(238, 238, 238);
  }

  .accordion-section__content--expanded {
    max-height: fit-content;
    transition: max-height 0.4s ease-in;
  }
}

.building-accordion-wrapper {
  //margin-top: 4px;
  border-top: 1px solid #c1c1c1;
  margin-top: 12px;
}

.building-accordion-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.1s ease;

  .building-accordion-section__header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-left: 30px;
    height: 32px;
  }

  .building-accordion-section_btn {
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: 4px;
    padding-left: 4px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 0px;
    border-bottom: 1px solid #c1c1c1;
    // border: 1px solid #808080b3;
    // border-radius: 3px;

    &:hover {
      background-color: #00000008;
      background-color: #7c7c7c0d;
    }

    .building-span {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: "Segoe UI";
      font-size: 11px;
      font-weight: 600;
    }

    .building-accordion-section__chevron {
      align-self: center;
      transition: transform 0s ease;
      height: 18px;
      width: 18px;
      stroke-width: 2;
      color: #484848;
    }
  }

  .building-accordion-section__chevron--expanded {
    transform: rotate(90deg);
  }

  .building-accordion-section__content {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0s ease-out;
    //padding-top: 8px;
  }

  .building-accordion-section__content--expanded {
    max-height: 2000px;
    transition: max-height 0s ease-in;
    padding-top: 8px;
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 8px;
  }
}

.sidebar-item__icon {
  max-height: 16px;
  margin-right: 8px;
}
