.results-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.results-content-wrapper {
  height: 100%;
  width: 100%;
  padding: 0 30px 30px 30px;
}

.results-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 14.4px 0 rgba(0, 0, 0, 0.13);
}

.results-content-map-container {
  position: relative;
  height: inherit;
  border: 1px solid #bebebec4;
  box-shadow: none;
}

.report-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border: 1px solid #bebebec4;
  padding: 20px;
}

.download-icon {
  width: 15px;
  color: #484848;
  background-color: transparent;
}

.delete-icon {
  width: 16px;
  color: #cc8585;
  stroke-width: 1.7px;
  &:hover {
    color: #d15a5a;
  }
}

.pen-icon {
  color: gray;
  width: 16px;
  stroke-width: 1.5px;
}

.layer-settings-icon-btn {
  background-color: white;
  outline: none;
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  justify-content: center;
  padding: 0px;
  border: 0px solid black;
  &:hover {
    cursor: pointer;
    background-color: #c4c4c43d;
  }
}

.layer-settings-icon-btn:disabled {
  &:hover {
    cursor: default;
    background-color: white;
  }
}

.layer-back-icon {
  width: 16px;
  color: gray;
  stroke-width: 1.7px;
  &:hover {
  }
}

.layer-back-icon-disabled {
  color: #b7b7b7;
}

.icon-disabled {
  cursor: default;
  color: rgba(128, 128, 128, 0.4);
  &:hover {
    cursor: default;
    background-color: #fff;
    color: rgba(128, 128, 128, 0.4);
  }
}

.create-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
