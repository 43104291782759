.new-proj-loader-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
}

.check-status-container {
  margin-top: 36px;
}

.check-status {
  font-family: "Segoe UI";
  text-align: center;
  font-size: 14px;
  margin-top: 7px;
  color: gray;
}

.check-status-active {
  color: #282828;
  font-weight: 600;
}
