.all-layout {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #f4f6f8;
}

.main-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 100%;
}
