.error-section {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #fc3939e3;
  padding: 8px;
  border-radius: 4px;
  padding: 2px 8px 8px 12px;

  .error-section__heading > label {
    font-size: 11px;
    font-weight: 600;
  }

  .error-section__list {
    padding: 0 0 0 6px;
    margin-bottom: 6px;
    margin-top: 6px;
    font-weight: 600;
    
    .error-li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;

      svg {
        flex-shrink: 0;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      .error-li__msg {
        font-size: 12px;
        margin: 0 0 0 8px;
      }
    }
  }
}
