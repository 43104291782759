.form-section_separator {
  width: 100%;
  height: 18px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  & > .form-section__label {
    color: #495057;
    font-weight: 600;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  & > .form-section__line {
    height: 1px;
    border: none;
    border-top: 1px solid rgba(100, 105, 110, 0.62);
  }
}
