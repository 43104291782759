@import "../../../common/variables.scss";

.form-group {
  display: flex;
  height: 40px;
  align-items: center;
}

.form-label {
  font-size: 11px;
  color: rgb(78, 78, 78);
  font-family: "Segoe UI", sans-serif;
  width: 400px;
  ///font-weight: 500; chrome good
  /// ff and chrome good
  font-weight: 600;
  //margin-bottom: 1px;
  margin-bottom: 4px;
  margin-bottom: 2px;
}

.user-settings-form-label {
  font-size: 12px;
}
.form-checkbox-label {
  width: 200px;
  font-size: 11px;
  color: rgb(78, 78, 78);
  font-family: "Segoe UI", sans-serif;
  font-weight: 600;
  margin-top: 4px;
}

.input-wrapper {
  height: 32px;
  width: 94%;
  display: flex;
  border: 1px #999999 solid;
  border-radius: 2px;

  &:hover,
  &:focus-within {
    background-color: $inputFocusedColor;
  }
}

.form-input {
  text-overflow: ellipsis;
  margin-left: 4px;
  padding-left: 9px;
  align-self: center;
  width: 100%;
  font-size: 11px;
  color: rgb(0, 0, 0);
  //font-family: "Segoe UI", sans-serif;
  //padding-bottom:2.5px;
  font-family: "Nunito Sans", sans-serif;
  height: 25px;
  border: 0px;
  background-color: rgba(255, 255, 255, 0);
  outline: 0;
  font-weight: 400;
}

.form-checkbox-input {
  width: auto;
  height: 14px;
  padding: 0;
  margin: 0;
}

.form-unit {
  padding-right: 8px;
  padding-right: 7px;
  border: none;
  align-self: center;
  font-family: "Segoe UI", sans-serif;
  font-size: 10px;
}

.box-draw {
  margin: 0 auto;
  margin-top: 20px;
  width: 85%;
  height: 4px;
  margin-bottom: 20px;
}

.form-select {
  width: 92%;
  height: 32px;
  font-size: 11.7px;
  padding-left: 5px;
  font-family: "Segoe UI", sans-serif;
  border: 1px solid #999999;
  border-radius: 2px;
  overflow: hidden;
  background-color: #fff;
}

.id-line {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.id-label {
  font-size: 9px;
  color: rgb(78, 78, 78);
  font-family: "Segoe UI", sans-serif;
  width: 400px;
  font-weight: 600;
  margin-right: 8px;
  white-space: nowrap;
  height: 14.815px;
  display: flex;
}

.switch-label {
  margin-left: 67px;
  font-size: 10px;
  color: #4e4e4e;
  font-family: "Segoe UI", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  width: 400px;
  font-weight: 600;
}

.login-group {
  margin-top: 20px;

  &:last-of-type {
    margin-bottom: 45px;
  }
}

.login-label {
  color: black;
  font-weight: 600;
  font-size: 17px;
}

.login-input {
  padding: 8px 15px 8px 15px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  border-radius: 5px;
  outline: none;
  border: solid 1px #c1c6cc;
  font-size: 17px;
  margin-top: 5px;

  &:hover,
  &:focus {
    transition: 1s;
    border: 1px solid #009373;
  }
}

.coords-group {
  display: contents;
}

.poly-select-wrapper {
  padding: 50px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 200px;
  padding-right: 20px;
}

.minified-select {
  font-size: 10px;
  height: 29px;
  display: inline-block;
  width: 100%;
}

.info {
  position: absolute;
  font-size: 11px;
  margin-top: 15px;
  margin-left: 30px;
  color: black;
  letter-spacing: 0.3px;
  font-family: Roboto;
}

.minified-label {
  font-size: 11px;
  color: #4e4e4e;
  font-family: "Segoe UI", sans-serif;
  width: 100px;
  font-weight: 500;
}

.tree-coord-btn {
  border: none;
  margin-right: 10px;
  border-radius: 3px;
  line-height: 0px;
  background-image: url("coordinates-icon.svg");
  height: 18px;
  width: 18px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  padding-left: 6px;
  padding-right: 6px;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;

  &:hover {
    outline: none;
    color: white;
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  }
}

.home-coord-btn {
  background-image: url("../Emission/EmissionEditor/home-icon-coordinates.svg");
}

.map-poi-coord-btn {
  background-image: url("../MapPoi/MapPoiEditor/map-poi-icon-coordinates.svg");
}

.wind-turbine-coord-btn {
  background-image: url("../WindTurbine/WindTurbineEditor/wind-turbine-coord-btn.svg");
}

.input-palm-types-label {
  margin-bottom: 4px;
}

.form-results-label {
  margin-bottom: 2px;
  width: auto;
  // width: fit-content;
  // width: min-content;
}

.form-results-select {
  width: 250px;
  color: black;
  &:disabled {
    color: #4e4e4e;
    appearance: none;
  }
}

.form-results-group {
  width: 100%;
  justify-content: space-between;
  &:disabled {
    color: #4e4e4e;
  }
}

.form-layer-select {
  height: 28px;
  //border-radius: 0px;
}

.form-layer-group {
  height: 34px;
}

.form-results-group-vis {
  height: 36px;
}

.form-results-inp-vis {
  height: 28px;
}

.form-results-inp-exp {
  width: 180px;
}

.form-results-sel-vis {
  height: 28px;
}

.form-results-exp-vis {
  width: 180px;
}

.poi-copy-disabled {
  pointer-events: none;
  opacity: 0;
}

.help-icon-link {
  display: flex;
  justify-content: center;
  width: 16.5px;
}
