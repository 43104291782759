.leaflet-ruler {
  height: 36px;
  width: 36px;
  background-image: url("./leaflet-ruler-icon.svg"); /* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */
  background-repeat: no-repeat;
  background-position: center;
  top: 203px;
  border: 0px solid black !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4)!important;
}
.leaflet-ruler:hover {
  // background-image: url("../dist/icon-colored.png"); /* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */
}
.leaflet-ruler-clicked {
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: center;
  // background-image: url("../dist/icon-colored.png");
  //border-color: chartreuse !important;
  background-color: #f4f4f4!important;
  box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75)!important;
}
.leaflet-bar {
  background-color: #ffffff;
}
.leaflet-control {
  cursor: pointer;
}
.result-tooltip {
  background-color: white;
  //border-width: medium;
  //border-color: #de0000;

  border-color: #e7e7e7;
  font-size: smaller;
}
.moving-tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  opacity: 0.5;
  // border: dotted;
  // border-color: rgb(143, 143, 143);
  font-size: smaller;
}
.plus-length {
  padding-left: 45px;
}

.leaflet-touch {
  border: 0px solid black;
}
