@import "../../../common/variables.scss";

.polygon-main-coords-container {
  font-size: 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 18px;
  align-items: center;
  letter-spacing: 0.2px;
}

.deltacoord-unit {
  padding-right: 35px;
  border: none;
  align-self: center;
  font-family: "Segoe UI", sans-serif;
  position: absolute;
  font-size: 9px;
  @-moz-document url-prefix() {
    overflow: hidden;
  }
}

.deltacoord-right-unit {
  margin-right: -255px;
  border: none;
  align-self: center;
  font-family: "Segoe UI", sans-serif;
  position: absolute;
  font-size: 9px;
  @-moz-document url-prefix() {
    overflow: hidden;
  }
}

.polygon-delta-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0px;
  margin-bottom: 5px;
}

.delta-lbl {
  color: rgb(90, 90, 90);
  font-size: 12px;
  font-weight: 500;
}

.polygon-hdr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.polygon-header {
  font-size: 13px;
  margin-bottom: 25px;
  font-weight: 400;
  color: #4f4f4f;
  justify-content: center;
  letter-spacing: 0.4px;
  margin-top: 0px;
}

.polygon-coord-container > *:first-child {
  margin-bottom: 10px;
  font-family: "Segoe UI", Verdana, sans-serif;
  font-size: 12px;
  justify-content: center;
}

.plus-minus-coords-container {
  display: flex;
  margin-left: 6px;
}

.polygon-coords-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.polygon-coords-input-row {
  justify-content: center;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.polygon-coord-input {
  margin-left: 5px;
  width: 120px;
  height: 22px;
  font-size: 10px;
  font-family: "Segoe UI", Verdana, sans-serif;
  padding-left: 10px;
  margin-right: 5px;
  font-weight: 500;
  border: 1px solid gray;
  border-radius: 2px;

  &:focus {
    background-color: #ced8d636;
    outline: 0;
  }
}

.coords-counter {
  width: 47px;
  font-size: 12px;
  font-family: "Segoe UI", Verdana, sans-serif;
  margin-bottom: 1px;
}

.changing-polygon-point-btn {
  outline: none;
  height: 18px;
  width: 13px;
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
  border: none;
  font-weight: 300;
  //border-radius: 1px;
  font-size: 17px;
  line-height: 0px;
  padding: 0 4px 0px 4px;
  font-family: "Segoe UI";
  padding-bottom: 5px;

  &:hover:enabled {
    background-color: rgb(243, 127, 127);
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
}

.add-polygon-point-btn {
  &:hover:enabled {
    background-color: #1e9f83;
    color: white;
    cursor: pointer;
  }
}

.lat-icon {
  height: 20px;
  width: 20px;
  background-image: url("latitude-icon.svg");
  display: inline-block;
  margin-right: 10px;
  margin-left: 74px;
}

.long-icon {
  height: 20px;
  width: 20px;
  background-image: url("longitude-icon.svg");
  display: inline-block;
  margin-left: -22px;
  margin-right: 10px;
}

.name-icon {
  background-image: url("name-icon.svg");
  height: 18px;
  width: 18px;
}

.coords-point-icon {
  margin-left: -15px;
  margin-right: 8px;
  display: flex;
}

.polygon-action-btn {
  color: #4e4e4e;
  font-size: 10px;
  font-weight: 600;
  font-family: "Segoe UI", sans-serif;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.polygon-action-lbl {
  margin-left: 6px;
}

.polygon-editor-id-line {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

// .leaflet-marker-icon.leaflet-interactive,
// .leaflet-image-layer.leaflet-interactive,
// .leaflet-pane > svg path.leaflet-interactive,
// svg.leaflet-image-layer.leaflet-interactive path {
//   z-index: 220!important;
// }

.vertex {
  &:hover,
  &:focus,
  &:focus-within {
    border-radius: 50%;
    outline: 2px solid #db2828 !important;
    outline-offset: -2.7px !important;
  }
}
