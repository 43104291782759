.editable-image__container {
  max-width: 600px;
  max-height: 300px;
  position: relative;
  display: flex;
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  .editable-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    box-shadow: 5px 10px 5px 0px rgba(66, 88, 65, 0.25);
  }

  .button-container {
    margin-top: auto;
    .circle-button {
      margin-left: 12px;
      margin-bottom: 4px;
    }
    .clear-image {
      background-color: #af1c1c;
      &:hover {
        background-color: #af1c1ccc;
      }
    }
  }
}

.placeholder {
  width: 450px;
  height: 225px;
  border-radius: 5px;
  background-color: #989898;
  box-shadow: 5px 10px 5px 0px rgba(66, 88, 65, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 5px 0px rgba(66, 88, 65, 0.25);

  .placeholder-text {
    font-family: "Segoe UI", sans-serif;
    font-size: 26px;
    line-height: 300px;
  }
}
