.avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
  font-family: "Segoe UI", sans-serif;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  .avatar-edit {
    position: absolute;

    .circle-button_icon {
      width: 17px;
      height: 17px;
    }
  }
}
.avatar-small {
  width: 28px;
  height: 28px;

  .avatar__initials {
    font-size: 13px;
    line-height: 28px;
  }
}
.avatar-medium {
  width: 45px;
  height: 45px;

  .avatar__initials {
    font-size: 18px;
    line-height: 45px;
  }
}
.avatar-large {
  width: 90px;
  height: 90px;

  .avatar__initials {
    font-size: 30px;
    line-height: 90px;
  }
}
.avatar-xlarge {
  width: 150px;
  height: 150px;

  .avatar-edit {
    position: absolute;
    padding: 6px;
    height: 32px;
    width: 32px;
    right: -10px;
    bottom: -20px;
  }
  .avatar-clear {
    position: absolute;
    padding: 6px;
    height: 32px;
    width: 32px;
    right: -35px;
    bottom: 10px;
    background-color: #da7070;
    &:hover {
      background-color: #af1c1ccc;
    }
  }

  .avatar__initials {
    font-size: 60px;
    line-height: 150px;
  }
}

.avatar-pic {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
}

.avatar__circular {
  border-radius: 50%;
  cursor: default;
}

.avatar__initials {
  display: inline;
  cursor: inherit;
}

.navbar-av {
  cursor: pointer;
}
