.expert-subpage-tree-container {
  padding: 4px;
}

.expert-subpage-tree {
  padding: 6px;
  font-size: 12px;
  font-family: "Segoe UI";
  cursor: pointer;
  padding-left: 12px;
  &:hover {
    color: #06896dba;
  }
}

.expert-subpage-tree-selected {
  color: #06896dba;
}

.expert-subpage-tree-disabled {
  color: rgba(0, 0, 0, 0.5);
  &:hover {
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
  }
}

.acc-exp-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.4s ease;
  font-family: "Segoe UI";

  //   .acc-exp-sec__header {
  //     display: flex;
  //     flex: 1;
  //     justify-content: space-between;
  //     height: 32px;
  //     font-size: 12px;
  //     font-weight: 600;
  //   }

  .acc-exp-sec_btn {
    color: #444;
    cursor: pointer;
    padding: 12px 8px 12px 8px;
    display: flex;
    align-items: center;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    border-bottom: 1px solid #aaa;
    &:hover {
      color: #06896dba;
    }

    span {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: "Segoe UI";
      font-weight: 500;
    }

    .acc-exp-sec__chevron {
      align-self: center;
      transition: transform 0.4s ease;
    }
  }

  .acc-exp-sec__chevron--expanded {
    transform: rotate(90deg);
  }

  .acc-exp-sec__content {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }

  .acc-exp-sec__content--expanded {
    max-height: fit-content;
    transition: max-height 0.4s ease-in;
  }
}

.save-expert-btn {
  width: 100%;
  margin-bottom: 12px;
  margin-top: 20px;
  color: #fff;
  background-color: #06896c9e;
  &:hover {
    background-color: #429c88ed;
    color: #fff;
  }
  &:hover:disabled {
    background-color: #f0f0f0;
    color: #4e4e4e;
  }
}
