.leaflet-map {
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.leaflet-map-hidden {
  opacity: 0;
}

img.leaflet-image-layer {
  opacity: 0.5;
}

.tree-style {
  background: none;
}

.raster-area {
  z-index: 1000000;
}

.buffer-area {
  z-index: 100000;
  pointer-events: none;
}
.markercluster-map {
  height: 90vh;
}

.marker-cluster {
  background-color: rgba(255, 255, 255, 0);
  background-image: url("tree-cluster.svg");
}
.marker-cluster div {
  background-color: rgba(255, 255, 255, 0);
}
.marker-cluster span {
  color: white;
  line-height: 5;
  font-weight: 600;
  font-size: smaller;
}

.marker-cluster-drawn-tree {
  background-image: url("drawn-tree-cluster.svg");
}

.tree-area {
  z-index: 500;
}

.marker-cluster-emission {
  background-image: url("emission-cluster.svg");
}

.marker-cluster-drawn-emission {
  background-image: url("drawn-emission-cluster.svg");
}

.marker-cluster-map-poi {
  background-image: url("map-poi-cluster.svg");
}

.marker-cluster-drawn-map-poi {
  background-image: url("drawn-map-poi-cluster.svg");
}

.marker-cluster-wind-turbine {
  background-image: url("wind-turbine-cluster.svg");
}

.marker-cluster-drawn-wind-turbine {
  background-image: url("drawn-wind-turbine-cluster.svg");
}

.wind-turbine-tooltip {
  min-width: 40px;
  display: flex;
  justify-content: center;
}

.wind-turbine-tooltip-hidden {
  display: none;
}

.map-poi-tooltip {
  min-width: 40px;
  display: flex;
  justify-content: center;
}

.map-poi-tooltip-hidden {
  display: none;
}

.isolines-tooltip {
  background-color: rgba(255, 255, 255, 0);
  border: 0px solid black;
  box-shadow: none;
  color: black;
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff,
    -1px -1px 0 #fff;
  stroke-width: 5;
  stroke-width: 2; /* Custom stroke width */
}

.isolines-tooltip:before {
  border-right: 0px solid rgba(0, 0, 0, 0);
  border-left: 0px solid rgba(0, 0, 0, 0);
}
