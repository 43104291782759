.background-maps-container {
  position: relative;

  .ws-select__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .maps-select {
    flex: 1;
    margin-right: 15px;
  }

  .map-input {
    padding: 15px;
  }

  .map-input__heading {
    color: #495057;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.modal {
  z-index: 1;
  border: 1px solid gray;
  border-radius: 4px;
  position: absolute;
  height: auto;
  right: 1px;
  top: 65px;
  width: 400px;
  background: #f4f6f8;
  outline: none;
  padding-bottom: 16px;
  box-shadow: 0 0 0 1px #0000001a, 0 4px 11px #0000001a;
  .form-line-input-wrapper:nth-of-type(2) {
    margin-top: 0px;
  }
  .validation-wrapper {
    position: absolute;
  }
}

div.map-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .option-delete__btn {
    min-height: 16px;
    min-width: 16px;
    height: 16px;
    width: 16px;
  }
}

.css-gg45go-NoOptionsMessage {
  color: #595959 !important;
  //padding: 4px 0 0 0 !important;
  display: flex;
  align-items: center;
  height: 20px;
  justify-content: center;
  font-weight: 400;
  font-size: 11px;
}

.css-1uccc91-singleValue {
  margin: 0px;
  color: #495057 !important;
}
