.notifications-container {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notification-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url("notification-bell.svg");
}
