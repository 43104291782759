.notification-list__container {
  position: absolute;
  right: -100px;
  top: 40px;
  width: 300px;
  height: auto;
  display: flex;
  min-height: 0;
  flex-direction: column;
  background: #ffffff;
  padding: 8px 8px;
  padding-bottom: 0px;
  cursor: default;
  transition: height 2s;
  overflow-x: hidden;
  border-bottom-left-radius: 5px;
  box-shadow: 0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22),
    0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18);
  -webkit-animation: slide-notification 0.3s
    cubic-bezier(0.05, 0.75, 0.53, 1.04) both;
  animation: slide-notification 0.3s cubic-bezier(0.05, 0.75, 0.53, 1.04) both;
  font-family: "Segoe UI";
  font-size: 13px;
  font-weight: 600;
}

@-webkit-keyframes slide-notification {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
@keyframes slide-notification {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}

.notification-list {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.notification-list__empty {
  border-top: 1px solid #e4e4e4;
  height: 100px;
  padding-left: 8px;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
}

.notifications__dismiss {
  position: absolute;
  width: 14px;
  height: 14px;
  left: calc(100% - 22px);
  cursor: pointer;

  &:hover {
    fill: rgba(0, 0, 0, 0.25);
  }
}

.notification-list__header {
  padding-top: 8px;
  padding-left: 8px;
}
