.about-info {
  min-width: 840px;
  max-width: 500px;
  color: #495057;
  overflow-wrap: break-word;
  font-family: "Segoe UI";
  line-height: 23px;
}

.about-break {
  margin-top: 24px;
}

.sponsor-img-section {
  display: flex;
  margin-top: 28px;
  align-items: center;
}

.sponsor-img-section-gap {
  gap: 6%;
  align-items: center;
  margin-bottom: 16px;
}

.about-img {
  max-width: 200px;
  height: auto;
}

.dlr-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dlr-img {
  height: max-content;
}
