.sidebar-btn {
  position: absolute;
  width: 20px;
  outline: 0;
  background: linear-gradient(to bottom, #009373 5%, #383838 100%);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  cursor: pointer;
  border: 1px solid #ffffff;
  box-sizing: border-box !important;
  z-index: 1000;
  top: 60px;

  &:hover {
    background: linear-gradient(to bottom, #383838 5%, #009373 100%);
  }
}

.sidebar-btn-expanded {
  height: 90vh;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  transition: height 0.5s, width 0.5s;
}

.sidebar-btn-collapsed {
  height: 35px;
  right: 10px;
  width: 35px;
  transition: height 0.5s, width 0.5s;
  border-radius: 4px;
  border: 1px solid #ffffff;
  padding: 5px;
}

.sidebar-burger {
  height: 100%;
  width: 100%;
}

@media (min-height: 0px) {
  .sidebar-btn-expanded {
    right: 0px;
    height: 80vh;
  }
}

@media (min-height: 320px) {
  .sidebar-btn-expanded {
    right: 10px;
  }
}

@media (min-height: 570px) {
  .sidebar-btn-expanded {
    height: 90vh;
  }
}

@media (min-height: 850px) {
  .sidebar-btn-expanded {
    height: 760px;
    max-height: 760px;
  }
}

.sidebar-btn-results-map {
  top: 20px;
  max-height: calc(100% - 40px);
}
