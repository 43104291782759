.layer-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px 0px 10px;
  width: 100%;
}

.layer-settings-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 0px solid black;
  outline: none;
  cursor: pointer;
  width: 100%;
  // width: -moz-available; /* WebKit-based browsers will ignore this. */
  // width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  &:hover {
    background-color: #d6d6d640;
  }
  &:active,
  &:focus {
    background-color: #06896c0d;
    box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.1);
  }
}

.new-layer-name-error {
  color: #ac4141;
  font-size: 12px;
  letter-spacing: 0.1px;
  margin-left: 6px;
  text-align: center;
}

.new-layer-success {
  color: rgb(6, 137, 108);
}

.difference-header {
  font-size: 12px;
  margin: 0;
  font-style: italic;
  text-align: center;
  font-family: "Segoe UI";
  cursor: default;
  color: rgba(0, 0, 0, 0.65);
  margin-top: -5px;
}
