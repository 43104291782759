.sim-palette-section {
  display: flex;
  flex-direction: column;
}
.palettes-container {
  padding-right: 40px;

  .palette-grid {
    margin-bottom: 14px;
  }

  button.wizard-button {
    width: auto;
    height: 32px;
    align-self: flex-end;
  }
}

.sim-palette-container {
  margin-top: 22px;
  margin-bottom: 21px;
}

.palette-reset_btn {
  align-self: flex-end;
}
