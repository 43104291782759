.progress-bar-containter {
  height: 31px;
  //width: 420px;
  background-color: white;
  border-radius: 2px;
  //margin-top: 4px;
  margin-bottom: 10px;
  border: 1px solid rgba(100, 105, 110, 0.62);
}

.progress-bar-filler {
  height: 100%;
  background-color: #41ac5a;
  border-radius: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.progress-bar-indicator {
  display: flex;
  justify-content: center;
  top: -25px;
  position: relative;
  font-size: 13px;
  cursor: default;
}
