.file-item {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
  box-shadow: 0px 0px 2px rgba(95, 95, 95, 0.6);
  padding: 3px 0px 3px 14px;

  .file-card__label {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    cursor: default;
  }
  .file-card__icons {
    display: flex;
    margin-left: auto;

    .loader {
      align-self: center;
    }

    .file-card__icon {
      height: 14px;
      &:hover {
        stroke: #06896c;
        cursor: pointer;
      }
    }
    *:last-child {
      margin-right: 0px;
    }

    .file-card__icon--delete {
      stroke: rgba(0, 0, 0, 0.65);
    }

    .file-card__icon--red {
      &:hover {
        stroke: #be2928;
      }
    }

    .file-card__icon--disabled {
      stroke: rgb(148, 148, 148);
      &:hover {
        stroke: rgb(148, 148, 148);
        cursor: default;
      }
    }
  }

  .file-card {
    .file-card-wrapper {
      position: relative;
      display: flex;
      height: 27px;
      width: 36px;
      border-radius: 5%;
      padding: 7px;
      justify-content: center;
      align-items: center;

      .file-card__icon--main {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.delete-file-list-btn {
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 0px solid #fff;
}
