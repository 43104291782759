.sidebar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Segoe UI";
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  font-weight: 500;
  color: rgba(66, 66, 66, 0.837);
  border-left: 3px solid transparent;
  text-decoration: none;

  &:hover {
    background-color: #06896c17;
    cursor: pointer;
  }
  &:active,
  &:focus {
    border-left: 3px solid #2d7464;
  }
  .sidebar-item__icon {
    max-height: 16px;
    margin-right: 8px;
  }
}

.sidebar-link {
  &:active,
  &:focus {
    border-left: none;
  }
}

.sidebar-item-disabled {
  color: rgba(145, 145, 145, 0.837);
  &:hover {
    cursor: default;
    border-left: 3px solid transparent;
    background-color: rgb(238, 238, 238);
  }
}

.active {
  cursor: pointer;
  color: rgb(0, 0, 0);
  color: #28292a;
  border-left: 3px solid #2d7464;
  background-color: #06896c17;
}

.sidebar-item__accordion {
  padding-left: 32px;
}
