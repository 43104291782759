.carousel-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 14.4px 0 rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
}

.carousel-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  //width: 100%;
  height: 100px;
  padding-left: 40px;
  ///
  overflow: hidden;
  text-align: center;
  //transform: translateX(-500px);
  position: absolute;
  transition: all 1s ease;
}

.carousel-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 140px;
  min-width: 110px;
  //margin-right: 20px;
  font-family: "Segoe UI";
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  cursor: pointer;
  //transition-duration: 1s;
  // &:hover {
  //   color: #1ecfa9d2;
  //   .city-city {
  //     fill: #1ecfa9d2;
  //   }
  // }
}

.carousel-item-icon {
  display: flex;
  justify-content: center;
}

.carousel-item-lbl {
  margin-top: 12px;
}

.carousel-arrow-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: white;
  cursor: pointer;
  height: 100px;
  z-index: 2;
}

.city-city {
  stroke-width: 2;
  fill: rgba(0, 0, 0, 0.65);
}

.carousel-item-active {
  //transform: scale(1.1);
  color: #06896c;
}

.carousel-icon-active {
  //transform: scale(1.1);
  fill: #06896c;
  .city-city {
    fill: #06896c;
  }
}

.arrow-wrapper-disabled {
  cursor: default;
}
