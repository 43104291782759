.form-text-wrapper {
  display: flex;
  flex-direction: column;

  .text-input-label {
    color: #495057;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    width: 100%;
    margin-bottom: 4px;
  }

  .form-text {
    font-size: 12px;
    width: 100%;
    color: #495057;
    padding: 12px 12px 12px 12px;
    //line-height: 1.6;
    border-radius: 3px;
    outline: 0;
    padding: 6px;
    box-sizing: border-box;
    border: solid 1px gray;
    height: 31px;
    &:focus {
      outline: 0;
      border: 1px solid rgba(0, 40, 100, 0.62);
    }
  }
}
