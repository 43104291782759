.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  box-sizing: border-box !important;
  margin: auto;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 95, 75, 0.2);
  border-right: 1.1em solid rgba(0, 95, 75, 0.2);
  border-bottom: 1.1em solid rgba(0, 95, 75, 0.2);
  border-left: 1.1em solid #005f4b;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.small-loader,
.small-loader:after {
  margin: 0px;
  border-radius: 50%;
  font-size: 3px;
  border-top: 5px solid rgba(0, 95, 75, 0.2);
  border-right: 5px solid rgba(0, 95, 75, 0.2);
  border-bottom: 5px solid rgba(0, 95, 75, 0.2);
  border-left: 5px solid #005f4b;
  align-self: end;
  margin-top: 25px !important;
  /* margin-left: 55px !important; */
  position: relative;
}

.small-osm-loader,
.small-osm-loader:after {
  margin: 0px;
  border-radius: 50%;
  font-size: 2.5px;
  border-top: 3px solid rgba(0, 95, 75, 0.2);
  border-right: 3px solid rgba(0, 95, 75, 0.2);
  border-bottom: 3px solid rgba(0, 95, 75, 0.2);
  border-left: 3px solid #005f4b;
  align-self: end;
  margin-top: 25px !important;
  margin-left: 55px !important;
  position: relative;
}

.export-results-loader {
  margin: 0px;
  border-radius: 50%;
  font-size: 2.5px;
  border-top: 4px solid rgba(0, 95, 75, 0.2);
  border-right: 4px solid rgba(0, 95, 75, 0.2);
  border-bottom: 4px solid rgba(0, 95, 75, 0.2);
  border-left: 4px solid #005f4b;
  align-self: end;
  position: relative;
}

.x-small-loader,
.x-small-loader:after {
  margin: 0px;
  border-radius: 50%;
  font-size: 1.8px;
  border-top: 2px solid rgba(0, 95, 75, 0.2);
  border-right: 2px solid rgba(0, 95, 75, 0.2);
  border-bottom: 2px solid rgba(0, 95, 75, 0.2);
  border-left: 2px solid #005f4b;
  align-self: end;
  position: relative;
}

.xx-small-loader,
.xx-small-loader:after {
  margin: 0px;
  border-radius: 50%;
  font-size: 1.6px;
  border-top: 3px solid rgba(0, 95, 75, 0.2);
  border-right: 3px solid rgba(0, 95, 75, 0.2);
  border-bottom: 3px solid rgba(0, 95, 75, 0.2);
  border-left: 3px solid #005f4b;
  align-self: end;
  position: relative;
}

.status-panel-loader,
.status-panel-loader:after {
  border-top: 1.1em solid rgba(94, 94, 94, 0.2);
  border-right: 1.1em solid rgba(94, 94, 94, 0.2);
  border-bottom: 1.1em solid rgba(94, 94, 94, 0.2);
  border-left: 1.1em solid #5e5e5e;
}

.light-loader.loader {
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
}

.light-loader.small-loader,
.light-loader.small-loader:after {
  border-top: 6px solid rgba(255, 255, 255, 0.2);
  border-right: 6px solid rgba(255, 255, 255, 0.2);
  border-bottom: 6px solid rgba(255, 255, 255, 0.2);
  border-left: 6px solid #ffffff;
}
.light-loader.x-small-loader,
.light-loader.x-small-loader:after {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #ffffff;
}

.simulation-loader,
.simulation-loader:after {
  border-radius: 50%;
  font-size: 2.5px;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #06896c;
  position: relative;
}

.big-loader,
.big-loader:after {
  margin: 0px;
  border-radius: 50%;
  font-size: 5px;
  border-top: 6px solid rgba(0, 95, 75, 0.2);
  border-right: 6px solid rgba(0, 95, 75, 0.2);
  border-bottom: 6px solid rgba(0, 95, 75, 0.2);
  border-left: 6px solid #005f4b;
  align-self: center;
  margin-top: 0px !important;
  /* margin-left: 55px !important; */
  position: relative;
}

.check-file-loader,
.check-file-loader:after {
  position: relative;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
