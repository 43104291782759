.sim-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.loader-sim-wrapper {
  display: flex;
  justify-content: center;
}

.sim-info-header {
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 13px;
}
.sim-info {
  margin-top: 2px;
  margin-bottom: 2px;
  word-break: break-word;
}

.sim-info-error {
  color: red;
  color: rgb(223, 70, 85);
  font-weight: 500;
}

.sim-info-warning {
  font-weight: 500;
  color: rgb(255, 136, 0);
}

.sim-check-inputs-btn {
  width: 100%;
  height: 40px;
  border: 1px solid rgb(102, 102, 102);
  background-color: #cacaca;
  color: rgb(102, 102, 102);
  outline: none;
  cursor: pointer;
  font-family: "Segoe UI";
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 2px;
  margin-bottom: 18px;
}

.sim-check-inputs-btn:hover {
  background-color: #d0d0d0;
}

.sim-check-inputs-btn:active {
  box-shadow: inset 0px 0px 6px -4px rgba(72, 72, 72, 0.75);
  background-color: #d0d0d0;
}

.sim-check-inputs-btn-disabled {
  cursor: default;
  &:hover {
    background-color: #cacaca;
  }
}

.sim-btn-inputs-text {
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.sim-btn-text-icon {
  padding-left: 10px;
}

.sim-start-btn {
  width: 100%;
  //height: 40px;
  border: 1px solid #e6e6e6;
  background-color: #41ac5a;
  color: white;
  outline: none;
  cursor: pointer;
  font-family: "Segoe UI";
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 4px;
  //margin-bottom: 20px;
  background-color: #06896cbd;
  &:active {
    box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
  }
  &:hover {
    background-color: #057d63bd;
  }
}

.sim-pause-btn {
  border: 1px solid #666666;
  background-color: rgb(172, 65, 65);
}

.sim-summary-area {
  //width: 420px;
  //height: auto;
  //background-color: white;
  //border: 1px solid rgba(155, 155, 155, 0.623);
  //border-radius: 2px;
  font-size: 12px;
  font-family: "Segoe UI";
}

.sim-summary-area-text {
  color: #666666;
  padding: 10px 20px 10px 10px;
  line-height: 1.5;
  font-family: "Segoe UI";
  cursor: default;
}

.sim-check-green {
  color: #3ec45c;
  font-weight: 600;
}

.sim-input-section-area {
  margin-bottom: 20px;
  font-family: "Trebuchet MS";
  font-family: "Segoe UI";
}

.sim-warning {
  background-color: #ffc6c65c;
  margin: 0;
  color: #a81d1dcf;
  padding: 6px;
  margin-bottom: 12px;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.2px;
  cursor: default;
}

.sim-left-box {
  width: 300px;

  a {
    color: rgba(255, 255, 255, 0);
  }
}

.sim-right-box {
  width: 550px;
  border: 1px solid gainsboro;
  //margin-left: 100px;
}

.sim-btn-disabled {
  width: 100%;
  height: 40px;
  border: 1px solid rgb(102, 102, 102);
  outline: none;
  font-family: "Segoe UI";
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 2px;
  margin-bottom: 18px;
  color: #a0a5aac7;
  background: #f7f7f7;
  border-color: #ddd;
  //margin-top: 20px;
  cursor: default;
}

.sim-separator-line {
  height: 1px;
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-top: 1px solid rgba(100, 105, 110, 0.62);
  margin-top: 40px;
  margin-bottom: 40px;
}

.sim-request-button {
  // background-color: #06896cbd;
  // margin-top: 20px;
  &:active {
    box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
  }
  &:hover {
    background-color: #057d63bd;
  }
}

.sim-go-results-btn {
  background-color: #06896cbd;
  //margin-top: 20px;
  &:active {
    box-shadow: inset 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
  }
  &:hover {
    background-color: #057d63bd;
  }
}

.sim-cancel-btn {
  background-color: #df4655cb;
  color: white;
  border: 1px solid gainsboro;
  font-weight: 600;
  font-family: "Segoe UI";
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #df4655cb;
    color: white;
  }
}

.sim-btn-go-results-disabled {
  margin-bottom: 0px;
}

.budget-hours-sim {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  padding: 9px;
}

.refresh-hours-sim {
  margin-top: 0px;
  padding: 0px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #f8f8f8;
  border: 1px solid #6666;
  border-radius: 3px;
  font-weight: 500;
}
