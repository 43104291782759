.panel {
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  position: absolute;
  border: solid 10px #ffffff00;
  border-radius: 4px 0px 0px 4px;
  box-sizing: border-box !important;
  padding: 10px;
  z-index: 1000;
  top: 60px;
  padding-bottom: 16px;
}

.panel-table {
  //was 415px
  max-width: 425px;
  height: 80vh;
  transition: height 0.5s, max-width 0.5s;
  overflow: auto;
  overflow-x: hidden;
  //right:400px!important
}

.panel-tree {
  //max-width: 535px;
  height: 80vh;
  transition: height 0.5s, max-width 0.5s;
  overflow: auto;
  overflow-x: hidden;
}

.panel-hidden {
  transition: height 0.5s, max-width 0.5s;
  height: 35px;
  max-width: 0px;
  border-right: 0px;
  border-left: 0px;
  border-top: solid 10px #ffffff;
  border-bottom: solid 10px #ffffff;
  right: 30px;
  padding: 0px;
  overflow: hidden;
  padding-top: 10px;
}

@media (min-height: 0px) {
  .panel-table {
    right: 20px;
    height: 80vh;
  }
}

@media (min-height: 320px) {
  .panel-table {
    right: 30px;
  }
}

@media (min-height: 570px) {
  .panel-table {
    height: 90vh;
  }
}

@media (min-height: 780px) {
  .table {
    font-size: 15px;
  }
}

@media (min-height: 850px) {
  .panel-table {
    height: 760px;
    max-height: 760px;
  }
}

.panel-settings {
  height: 795px;
  max-height: 795px;
}

.panel-results-map {
  top: 20px;
  max-height: calc(100% - 40px);
  width: 425px;
}
