.app-field-button-wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
}

.wizard-section__app-field {
  .ui-select-wrapper {
    margin-top: 20px;
  }
}
