.ag-center-cols-clipper {
  min-height: 100% !important;
}

.wind-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  font-weight: 500;
}

.wind-parent-hdr {
  display: grid !important;
  justify-content: center !important;
  padding: 0 !important;
  font-weight: 500;
  font-family: "Segoe UI" !important;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.65);
}

.wind-hdr {
  padding: 0 !important;
  font-weight: 500 !important;
  font-family: "Segoe UI" !important;
  background-color: #f8f8f8;
}

.wind-hdr div div span {
  margin: 0 auto !important;
}

.wind-table-first-hdr {
  background-color: #f8f8f8;
  font-family: "Segoe UI" !important;
  background-image: url("./compas.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  border-right: 1px solid #d7dade !important;
}

.wind-table-dir-col {
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  padding-right: 0px;
  padding-left: 0px;
  border-right: 1px solid #d7dade !important;
  align-items: center;
  font-family: "Segoe UI";
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}

.chemistry-table-dir-col {
  display: flex !important;
  justify-content: flex-start;
  background-color: #f8f8f8;
  padding-right: 0px !important;
  padding-left: 6px !important;
  border-right: 1px solid #d7dade !important;
  align-items: center;
  font-family: "Segoe UI" !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.wind-cell {
  display: flex !important;
  justify-content: center !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 12px;
  text-align: center !important;
  height: 100%;
  //vertical-align: middle !important;
  align-items: center;
  font-family: "Segoe UI" !important;
}

.wind-table-first-header-sim {
  background-attachment: unset;
}

.chemistry-table-container {
  display: flex;
  flex-direction: row;
  font-weight: 500;
}

.expert-hdr {
  font-weight: 600;
  border-right: 1px solid #d7dade;
  font-size: 12px;
  font-family: "Segoe UI";
  text-align: center;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.expert-hdr {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-theme-alpine .ag-cell-inline-editing {
  height: inherit !important;
  border-radius: 0px !important;
  text-align: center!important;
}

.expert-cell {
  border-right: 1px solid #d7dade !important;
  font-family: "Segoe UI" !important;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px !important;
  &:hover {
    background-color: #fff;
  }
}

.expert-disabled {
  font-size: 12px;
  background-color: #f8f8f8 !important;
  justify-content: center;
}

.expert-disabled-prim {
  font-size: 12px;
  background-color: #f8f8f8 !important;
  justify-content: center;
}

.ag-row-hover {
  background-color: #fff !important;
}

.expert-left {
  text-align: left;
  padding-left: 12px;
}

.expert-right {
  text-align: right;
  padding-left: 12px;
}

.expert-center {
  text-align: center;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.ag-theme-alpine .ag-ltr input[class^="ag-"][type="text"]{
  text-align: center;
}