.box-type {
  width: 100%;
  height: 5px;
  background-color: #d66565;
  margin-bottom: 10px;
  margin-top: 10px;
}

.water {
  background-color: #19a1a3;
}

.vegetation {
  background-color: #2dbe7f;
}

.pavement {
  background-color: #8c9597;
}

.tree {
  background-color: #00b365;
}

.singleTree {
  background-color: #33b428;
}

.street {
  background-color: rgb(230, 179, 0);
}

.point_emission,
.emission {
  background-color: rgb(230, 179, 0);
}

.point_of_interest {
  background-color: #c21aa8ff;
}

.wind_turbine {
  background-color: rgb(26, 85, 194);
}
