.project-heading {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;

  & > .editable-image__container {
    margin-bottom: 24px;
  }

  & > *:not(:last-child) {
    margin-right: 40px;
  }
}

.case-button {
  width: 110px;
  margin-top: 15px;
  margin-bottom: 15px;
}

#update-proj__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  span {
    margin-right: 8px;
  }
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}