.login-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 100%;
  padding-right: 18px;
  padding-left: 18px;

  & > *:not(:last-child) {
    margin-right: 12px;
  }
}
