.action-btn-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 130px;
  left: 10px;
  height: 336px;
  width: auto;
  z-index: 800;
  pointer-events: none;
  z-index: 1000;
}

.map-panel-status-bar {
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 1000;
  left: 120px;
  top: 52px;
  font-size: 13px;
  gap: 18px;
}

.static-driver-indicator {
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 4px 10px 4px 8px;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-family: "Segoe UI";
  color: #6e6e6e;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.saving-status-indicator {
  left: 240px;
}

.static-driver-info {
  padding-left: 4px;
  cursor: default;
}
