 .users-list {
  display: flex;
  flex-direction: column;

  .user-li {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: 1fr 45px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 15px 24px;
    margin-bottom: 12px;
    box-shadow: 0px 0px 2px rgba(95, 95, 95, 0.6);

    .user-title {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      margin-top: 28px;

      .user-li__mail {
        font-weight: 300;
      }
    }

    .user-li__roles {
      display: flex;
      flex-direction: row;
      margin-right: left;
      justify-self: end;

      .form-group {
        height: auto;
        margin-bottom: 5px;
      }

      .xinput-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 5px;
        margin-top: 0px;

        input {
          height: 24px;
          width: 70px;
          margin-left: 10px;
          text-align: right;
        }
      }
    }

    .wizard-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      grid-column-start: 3;
      grid-column-end: 3;
      margin-top: 10px;
      margin-left: auto;

      .loader {
        margin-left: 5px;
      }
    }
  }
}
