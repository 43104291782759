@import url("https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto&display=swap");

html,
body {
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

#root {
  height: 100%;
  width: 100%;
}
