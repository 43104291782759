.notification {
  position: relative;
  width: 100%;
  min-height: 55px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 8px;
  border-top: 1px solid #e4e4e4;

  .notification__title {
    font-weight: 800;
  }

  .notification__body {
    padding-top: 4px;
  }
  .notification__date {
    align-self: flex-start;
    height: auto;
    font-size: 0.7rem;
  }

  .notification__dismiss {
    position: absolute;
    width: 14px;
    height: 14px;
    left: calc(100% - 14px);
    cursor: pointer;

    &:hover {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
}
